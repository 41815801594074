import TYPES from '@/types';
import { v4 as uuid } from 'uuid';
import i18n from '@/vue-app/plugins/i18n';
import {
  requiredRule,
  requiredRuleByKey,
  maxLengthRule,
} from '@/vue-app/utils/form-rules';

// Application
import {
  SearchOccupationsQuery,
} from '@/modules/my-investment/catalogs/allianz/occupation/application/queries';
import {
  SearchActivitiesQuery,
} from '@/modules/my-investment/catalogs/allianz/activity/application/queries';
import {
  SearchResourcesOriginsQuery,
} from '@/modules/my-investment/catalogs/allianz/resource-origin/application/queries';
import GetPersonQueryService
  from '@/modules/my-investment/person/application/queries/get-my-investment-person-query';
import {
  SearchOnBoardingStepsQuery,
} from '@/modules/my-investment/on-boarding-steps/application/queries';
import {
  SearchInvestmentProvidersQuery,
} from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';
import {
  SearchSourcesIncomesQuery,
} from '@/modules/my-investment/catalogs/allianz/source-income/application/queries';

// Domain
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';

export default class ContractSavingsProfilingWorkAllianzViewModel {
  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_OCCUPATION_QUERY)
  private readonly searchOccupationsQuery!: SearchOccupationsQuery;

  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_ACTIVITY_QUERY)
  private readonly searchActivitiesQuery!: SearchActivitiesQuery;

  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_RESOURCE_ORIGIN_QUERY)
  private readonly searchResourcesOriginsQuery!: SearchResourcesOriginsQuery;

  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_SOURCE_INCOME_QUERY)
  private readonly searchSourcesIncomesQuery!: SearchSourcesIncomesQuery;

  @Inject(TYPES.GET_INVESTMENT_PERSON_QUERY)
  private readonly getPersonQueryService!: GetPersonQueryService;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  private readonly searchInvestmentProvidersQuery!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  readonly i18n_namespace = 'components.contract-savings.profiling.work.allianz';

  readonly error_message = i18n.tc('components.contract-savings.error_on_save_step');

  small_screen: null | boolean = null;

  qualify_as_pep = 'no';

  internal_step = 1;

  inputs = {
    job: {
      cve_ocupacion: null,
      desc_ocupacion: null,
    },
    other_job: '',
    labor_shift: {
      cve_actividad: null,
      desc_actividad: null,
    },
    other_labor_shift: '',
    resources_source: {
      cve_ori_recurso: null,
      desc_ori_recurso: null,
    },
    main_source_of_income: {
      cveFuenteIngreso: null,
      descFuenteIngreso: null,
    },
  };

  active_other_labor_shift = false;

  active_other_job = false;

  investment_provider_name = 'allianz';

  exists_step = false;

  step_name = 'job_profile';

  investment_provider_id = '';

  is_loading = true;

  on_boarding_step: OnBoardingStepEntity = {
    id: uuid(),
    current_step: '',
    investment_provider_id: '',
    payload: {
      job: {
        cve_ocupacion: null,
        desc_ocupacion: null,
      },
      other_job: '',
      labor_shift: {
        cve_actividad: null,
        desc_actividad: null,
      },
      other_labor_shift: null,
      resources_source: {
        cve_ori_recurso: null,
        desc_ori_recurso: null,
      },
      main_source_of_income: {
        cveFuenteIngreso: null,
        descFuenteIngreso: null,
      },
      confirm_not_pep: false,
    },
  };

  rules = {
    other_job: [requiredRule, (value: string) => maxLengthRule(15, value)],
    other_labor_shift: [requiredRule, (value: string) => maxLengthRule(20, value)],
    qualify_as_pep: [requiredRule],
  };

  custom_rules = {
    job: [
      requiredRule,
      (value: string) => requiredRuleByKey(value, 'cve_ocupacion'),
    ],
    labor_shift: [
      requiredRule,
      (value: string) => requiredRuleByKey(value, 'cve_actividad'),
    ],
    resources_source: [
      requiredRule,
      (value: string) => requiredRuleByKey(value, 'cve_ori_recurso'),
    ],
    main_source_of_income: [
      requiredRule,
      (value: string) => requiredRuleByKey(value, 'cveFuenteIngreso'),
    ],
  };

  form_validity = false;

  show_politically_exposed_person = false;

  get activities() {
    return this.searchActivitiesQuery.execute();
  }

  get occupations() {
    return this.searchOccupationsQuery.execute();
  }

  get resourcesOrigins() {
    return this.searchResourcesOriginsQuery.execute();
  }

  get sourceIncomes() {
    return this.searchSourcesIncomesQuery.execute();
  }

  get investmentProviders() {
    return this.searchInvestmentProvidersQuery.execute();
  }

  get userName() {
    return this.getPersonQueryService.execute().name;
  }

  get steps() {
    return this.searchOnBoardingStepsQuery.execute(this.investment_provider_id);
  }

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  verifyStep = async (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    if (search_step) {
      this.exists_step = true;
      this.on_boarding_step = search_step;
      this.setInputsDataFromStep();
    }
  };

  setInputsDataFromStep = () => {
    this.inputs.job = this.on_boarding_step.payload.job;
    this.inputs.other_job = this.on_boarding_step.payload.other_job;
    this.inputs.labor_shift = this.on_boarding_step.payload.labor_shift;
    this.inputs.other_labor_shift = this.on_boarding_step.payload.other_labor_shift;
    this.inputs.resources_source = this.on_boarding_step.payload.resources_source;
    this.inputs.main_source_of_income = this.on_boarding_step.payload.main_source_of_income;
    this.qualify_as_pep = this.on_boarding_step.payload.confirm_not_pep ? 'no' : 'yes';
  }

  async saveStep() {
    this.is_loading = true;
    try {
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      this.on_boarding_step.payload.job = this.inputs.job;
      this.on_boarding_step.payload.other_job = this.inputs.other_job;
      this.on_boarding_step.payload.labor_shift = this.inputs.labor_shift;
      this.on_boarding_step.payload.other_labor_shift = this.inputs.other_labor_shift;
      this.on_boarding_step.payload.resources_source = this.inputs.resources_source;
      this.on_boarding_step.payload.main_source_of_income = this.inputs.main_source_of_income;
      this.on_boarding_step.payload.confirm_not_pep = this.qualify_as_pep === 'no';
      if (this.exists_step) {
        delete this.on_boarding_step.customer_id;
        await this.updateOnBoardingStepCommand.execute(this.on_boarding_step);
      } else {
        await this.createOnBoardingStepCommand.execute(this.on_boarding_step);
      }
    } catch {
      this.messageNotifier.showErrorNotification(this.error_message);
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  initialize = async () => {
    const investment_providers = await this.investmentProviders;
    this.setInvestmentProviderId(investment_providers);

    const steps = await this.steps;
    await this.verifyStep(steps);
    this.is_loading = false;
  }
}
